<template>
    <article class="new-reservation-card">

        <a-col style="padding: 0; height: 280px; min-width: 204px;">
            <a-col flex="120px" style="
                    padding-left: 12px;
                    padding-right: 12px;
                    margin-bottom: 12px;
                ">
                <router-link v-if="route?.path === '/main'" :to="{
                    name: 'reservationSchedule',
                    params: { id: record?.object?.id },
                }">
                    <app-image v-if="record?.object?.name?.includes('Локер')" :src="'/images/AnimaPhotosEmpty/Locker00.png'"
                        width="204px" height="204px" style="" />
                    <app-image v-else-if="record?.object?.name?.includes('Переговорная')"
                        :src="'/images/AnimaPhotosEmpty/MR00.png'" width="204px" height="204px" style="" />
                    <app-image v-else-if="record?.object?.name?.includes('Парковка')"
                        :src="'/images/AnimaPhotosEmpty/Parking00.png'" width="204px" height="204px" style="" />
                    <app-image v-else-if="record?.object?.name?.includes('Рабочее место')"
                        :src="'/images/AnimaPhotosEmpty/WP00.png'" width="204px" height="204px" style="" />

                    <app-image v-else :src="'/images/AnimaPhotosEmpty/MR00.png'" width="204px" height="204px" />
                </router-link>

                <router-link v-else :to="{
                    name: 'reservationSchedule',
                    params: { id: record?.object?.id },
                }">
                    <app-image :src="record?.object?.previewUrl" width="204px" height="204px" />
                </router-link>

                <a-row :gutter="16" type="flex" justify="space-between" v-if="reservation" :style="{
                    flexWrap: 'nowrap',
                    position: 'absolute',
                    bottom: '5%',
                    right: '10%',
                }
                    ">
                    <a-col>
                        <span class="paragraph-5" :style="{
                            color: '#FFFFFF',
                            background: 'rgba(26, 26, 35, 0.80)',
                            borderRadius: '8px',
                            padding: '4px 8px',
                        }
                            ">
                            {{
                                moment(
                                    record?.timeFrom,
                                    "YYYY-MM-DDTHH:mm:ss"
                                ).format("DD.MM.YYYY | HH:mm")
                            }}
                            &ndash;
                            {{
                                moment(
                                    record?.timeTo,
                                    "YYYY-MM-DDTHH:mm:ss"
                                ).format("HH:mm")
                            }}
                        </span>
                    </a-col>
                </a-row>
            </a-col>
            <a-row :gutter="16" type="flex" justify="space-between" :style="{
                zIndex: 1,
                flexWrap: 'nowrap',
                position: 'absolute',
                bottom: '230px',
                right: '25px',
            }">
                <a-col>
                    <h6>
                        <span class="valign-middle" :style="{ fontSize: '1.25rem', zIndex: '1' }"
                            :class="{ 'cursor-none': loadingFavourites }">
                            <loading-outlined v-if="loadingFavourites" class="primary-color-text" />
                            <heart-filled v-else-if="record?.object?.favourite" class="error-color-text clickable-image"
                                @click="changeFavourite(false)" />
                            <heart-outlined v-else style="color: #FFFFFF;" class="clickable-image"
                                @click="changeFavourite(true)" />
                        </span>
                    </h6>
                </a-col>
            </a-row>
            <router-link :to="{
                name: 'reservationSchedule',
                params: { id: record?.object?.id },
            }">
                <a-col style="padding-left: 12px; padding-right: 12px" flex="auto" :style="{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    justifyContent: 'space-between',
                }">


                    <p :style="{
                        textAlign: 'start',
                        width: '204px',
                        marginBottom: '2px',
                        overflow: 'hidden',
                        color: 'rgba(26, 26, 35, 0.48)',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'Aeroport',
                        fontSize: '13px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '18px',
                    }">
                        {{
                            renderObjectPath(
                                record?.object?.path || [],
                                false,
                                ", "
                            ) || "Верхний уровень"
                        }}
                    </p>
                    <p :style="{
                                color: '#1A1A23',
                                fontFamily: 'Aeroport',
                                fontSize: '17px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '22px', /* 129.412% */
                                letterSpacing: '-0.34px',
                                textAlign: 'start',
                                maxWidth: '204px'
                            }">
                        {{ record?.object?.name }}
                    </p>

                    <!-- Время бронирования: -->


                </a-col>
            </router-link>
        </a-col>

        <!-- Модалка приглашения участников -->
        <a-modal v-model:visible="invitedPeoplesModalVisible" title="Ваша бронь" width="400px">
            <reservation-preview :name="record?.object?.name" :code="record?.object?.code"
                :preview-url="record?.object?.previewUrl" show-reservation-info :reservation="record" />

            <a-divider />

            <a-form layout="vertical">
                <a-form-item label="Пригласить участников">
                    <invite-user-list :list="invitedUsers" />
                </a-form-item>
            </a-form>

            <template #footer>
                <a-button key="cancel" size="large" @click="invitedPeoplesModalVisible = false">
                    Отмена
                </a-button>
                <a-button key="submit" size="large" type="primary" @click="handleSetInvitedSubmit">
                    Сохранить
                </a-button>
            </template>
        </a-modal>

        <!-- Модалка отмены бронирования -->
        <a-modal v-model:visible="cancelModalVisible" title="Отменить бронирование?" width="400px">
            <reservation-preview :name="record?.object?.name" :code="record?.object?.code"
                :preview-url="record?.object?.previewUrl" show-reservation-info :reservation="record" />

            <template #footer>
                <footer class="ant-modal-confirm-btns">
                    <a-button key="cancel" size="large" @click="cancelModalVisible = false">
                        Не отменять
                    </a-button>
                    <a-button key="submit" size="large" type="danger" @click="handleCancelReservationSubmit">
                        Да, отменить
                    </a-button>
                </footer>
            </template>
        </a-modal>
    </article>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { onMounted, unref } from "@vue/runtime-core";
import {
    HeartOutlined,
    HeartFilled,
    LoadingOutlined,
} from "@ant-design/icons-vue";
import ReservationPreview from "@/components/admin/reservation/ReservationPreview";
import InviteUserList from "@/components/admin/reservation/InviteUserList";
import {
    getOneData as getOneObject,
    requestAPI as objectAPI,
} from "@/compositions/objects";
import AppImage from "../shared/ul/AppImage";
import { Modal, notification } from "ant-design-vue";
import {
    requestAPI as reservationAPI,
    getOneData as reservationData,
} from "@/compositions/reservations";
import moment from "moment";
import { useRoute } from "vue-router";

export default {
    name: "NewReservationCard",
    props: {
        reservation: Object,
        object: Object,
        isSlot: Boolean,
        allowReserveTime: Boolean,
        disabled: Boolean,
    },
    emits: ["cancel", "finish"],
    setup(props, { emit }) {
        const route = useRoute();
        const { reservationFilters } = reservationData();
        const { updateReservation } = reservationAPI();
        const { renderObjectPath } = getOneObject();
        const { addObjectToFavourites, removeObjectFromFavourites } =
            objectAPI();

        const record = ref({});
        const invitedPeoplesModalVisible = ref(false);
        const invitedUsers = ref([]);
        const cancelModalVisible = ref(false);
        const loadingFavourites = ref(false);

        const canCancel = computed(
            () =>
                !props.isSlot &&
                (unref(record)?.actions || []).indexOf("cancel") >= 0
        );
        const canFinish = computed(
            () =>
                !props.isSlot &&
                (unref(record)?.actions || []).indexOf("finish") >= 0
        );
        const canInvite = computed(
            () =>
                !props.isSlot &&
                (unref(record)?.actions || []).indexOf("invite") >= 0
        );

        onMounted(async () => {

            record.value = props.reservation || {};
            if (props.object) {
                record.value.object = props.object;
            }
        });

        async function changeFavourite(newState) {
            loadingFavourites.value = true;
            try {
                if (newState) {
                    await addObjectToFavourites(record.value?.object?.id);
                } else {
                    await removeObjectFromFavourites(record.value?.object?.id);
                }
                record.value.object.favourite = newState;
            } catch (e) {
                console.error(e);
            } finally {
                loadingFavourites.value = false;
            }
        }

        function changeInvited() {
            invitedUsers.value = [...(unref(record)?.invitedUsers || [])];
            invitedPeoplesModalVisible.value = true;
        }

        async function handleSetInvitedSubmit() {
            try {
                await updateReservation(
                    { invitedUsers: unref(invitedUsers) },
                    unref(record)?.id
                );
                record.value.invitedUsers = unref(invitedUsers);
                notification.success({
                    message: "Успех",
                    description: "Парамеры бронирования сохранены",
                });
                invitedPeoplesModalVisible.value = false;
            } catch (e) {
                console.error(e);
            }
        }

        function cancelReservation() {
            cancelModalVisible.value = true;
        }

        function handleCancelReservationSubmit() {
            emit("cancel", unref(record)?.id);
            cancelModalVisible.value = false;
        }

        function finishReservation() {
            Modal.confirm({
                title: "Завершить бронирование?",
                okText: "Завершить",
                okType: "primary",
                cancelText: "Отмена",
                async onOk() {
                    emit("finish", unref(record)?.id);
                },
                onCancel() { },
            });
        }

        return {
            moment,
            invitedUsers,
            handleSetInvitedSubmit,
            record,
            loadingFavourites,
            invitedPeoplesModalVisible,
            renderObjectPath,
            cancelModalVisible,
            handleCancelReservationSubmit,
            changeFavourite,
            changeInvited,
            cancelReservation,
            finishReservation,
            reservationFilters,
            canCancel,
            canFinish,
            canInvite,
            route,
        };
    },
    components: {
        AppImage,
        InviteUserList,
        ReservationPreview,
        HeartOutlined,
        HeartFilled,
        LoadingOutlined,
    },
};
</script>

<style lang="less">
.preview-img {
    position: static;
}

.image-container {
    display: flex;
    position: relative;
}

.new-reservation-card {
    padding: 1.25rem 0 0;
}
</style>
